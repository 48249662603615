import { NgModule } from '@angular/core';
import { SpecialProductComponent } from './special-product.component';
import { SpecialProductService } from './special-product.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [SpecialProductComponent],
    exports: [SpecialProductComponent],
    imports: [CommonModule, RouterModule],
    providers: [SpecialProductService],
  })
  export class SpecialProductModule { }