import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class PartService {
  rootPath = ''
  partsUrl = this.rootPath + '/api/part/PartCategories';
  partUrl = this.rootPath + '/api/part/GetPart/';

  constructor(private http: HttpClient) { }


  getParts(partFilter) {
    return this.http.post(this.partsUrl, partFilter);
  }

  getPart(id: number) {
    return this.http.get(this.partUrl + id);
  }
}
