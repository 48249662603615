import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UserRegisterModel } from '../models/user';
import { Observable } from 'rxjs';
import { Globals } from './global-variable.services';


@Injectable()
export class UserService {
  rootPath = '';
  partsUrl = this.rootPath + '/api/Account/users';
  isLoggedIn: false;
  private readonly loginUrl: string = '/connect/token';
  private readonly getCurrentUserUrl: string = '/api/Account/users/me';

  constructor(private http: HttpClient, private globals: Globals) { }
  login(userName: string, password: string, rememberMe?: boolean) {



    if (this.isLoggedIn) {
      this.logout();
    }

    const header = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    const params = new HttpParams()
      .append('username', userName)
      .append('password', password)
      .append('grant_type', 'password')
      .append('scope', 'openid email phone profile offline_access roles');

    const requestBody = params.toString();

    return this.http.post(this.loginUrl, requestBody, { headers: header });
  }

  getCurrentUser() {
    const token = this.globals.getToken();
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token.access_token}`); // Not added yet as this is the reason for the question
    return this.http.get(this.getCurrentUserUrl, {headers});
  }

  logout() {
    this.isLoggedIn = false;
  }
  register(userRegister: UserRegisterModel) {
    return this.http.post(this.partsUrl, userRegister);
  }
}
