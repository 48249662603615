import { Component, OnInit, ViewChild, HostListener } from "@angular/core";
import { CartModel } from "src/app/models/cart";
import { HeaderModel } from "src/app/models/header";
import { Globals } from "src/app/services/global-variable.services";
import { MatMenuTrigger, MatDialog } from "@angular/material";
import { RegisterModalComponent } from "../shared/register-modal/register-modal.component";

@Component({
  selector: "laco-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;

  title = "laco-app";
  cart: CartModel[];
  menu: HeaderModel[];
  isMenuShown = true;
  userAdmin = false;

  constructor(private globals: Globals, private dialog: MatDialog) {}

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    this.globals.onCartChanged.subscribe((cart) => {
      this.cart = cart;
    });
    this.globals.getCart();
    this.menu = this.globals.getMenu();
    this.userAdmin = this.globals.isAdmin();
  }

  getTotalPrice(): number {
    let totalPrice = 0;
    this.cart.forEach((item) => {
      totalPrice += item.price * item.quantity;
    });
    return totalPrice;
  }

  signOut() {
    this.globals.singOut();
  }

  isLogin() {
    return !!this.globals.getUser();
  }

  registerModal(): void {
    const dialogRef = this.dialog.open(RegisterModalComponent, {
      width: "500px",
    });
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    this.trigger.closeMenu();
  }
}
