import { NgModule } from '@angular/core';
import { FilterBannerComponent } from './filter-banner.component';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [FilterBannerComponent],
    exports: [FilterBannerComponent],
    imports: [MatFormFieldModule, MatSelectModule, CommonModule],
    providers: [],
  })
  export class FilterBannerModule { }