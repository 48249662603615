import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsItemComponent } from './news-item.component';
import { NewsService } from 'src/app/services/news.service';

@NgModule({
    declarations: [NewsItemComponent],
    imports: [CommonModule],
    providers: [NewsService],
})
export class NewsItemModule { }