
export class PartCategory {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(description?: string, type?: number) {

    this.description = description;
    this.isChecked = false;
    this.type = type;
  }

  public description: string;
  public isChecked: boolean;
  public type: number;
}
