import { Component, OnInit } from "@angular/core";
import { Article } from "src/app/models/article";
import { ActivatedRoute } from "@angular/router";
import { Globals } from "src/app/services/global-variable.services";
import { CartModel, CartType } from "src/app/models/cart";
import { DetailsService } from "src/app/services/detail.services";
import { PartDescription } from "src/app/models/part-description";

@Component({
  selector: "laco-detail",
  templateUrl: "./detail.component.html",
  styleUrls: ["./detail.component.scss"],
})
export class DetailComponent implements OnInit {
  id: number;
  article: Article = new Article();
  leadImage: string;

  constructor(
    private route: ActivatedRoute,
    private detailsService: DetailsService,
    private globals: Globals
  ) {
    this.id = this.route.snapshot.params.id;
  }

  ngOnInit() {
    this.globals.setActiveMenu("Ostalo");
  }

  getArticle() {
    this.detailsService.getArticle(this.id).subscribe((article: Article) => {
      this.setArticle(article);
      if (!this.article.images) {
        this.article.images = [];
      }
    });
  }

  setArticle(article: Article) {
    this.article = article;
    if (this.article.images) {
      this.leadImage = this.article.images[0].path;
    }
  }
  getPart() {
    this.detailsService.getPart(this.id).subscribe((article: Article) => {
      this.setArticle(article);
      if (!this.article.images) {
        this.article.images = [];
      }
    });
  }

  addToCart(article: Article) {
    const cartItem = new CartModel(
      article.id,
      article.category.value,
      article.name,
      article.price,
      article.quantity,
      "",
      CartType.Article
    );
    this.globals.addToCart(cartItem);
  }

  removeImage(id: number) {
    this.detailsService.removeImage(id).subscribe(() => {
      const image = this.article.images.find((dalimage) => dalimage.id === id);
      const index = this.article.images.indexOf(image);
      this.article.images.splice(index, 1);
      if (this.article.images) {
        this.leadImage = this.article.images[0].path;
      } else {
        this.leadImage = "";
      }
    });
  }

  isAdmin(): boolean {
    if (this.globals.getUser()) {
      return (this.globals.getUser().roles || []).indexOf("administrator") > -1;
    }
    return false;
  }

  discauntQuantity(article) {
    if (article.quantity > 0) {
      article.quantity--;
    }
  }

  addQuantity(article: Article) {
    if (article.quantity < article.stock) {
      article.quantity++;
    }
  }

  removeFromCart(article) {
    this.globals.removeFromCart(article);
  }

  savePartDescription(desc: PartDescription) {
    this.detailsService.updatePartDescription(desc).subscribe((parts) => {});
  }

  saveArticleDescription(desc: PartDescription) {
    this.detailsService.updateArticleDescription(desc).subscribe((parts) => {});
  }

  onPartFileChanged(event) {
    const imageLength = event.target.files.length;
    for (let i = 0; i < imageLength; i++) {
      this.detailsService
        .uploadPartImage(event.target.files[i], this.id)
        .subscribe((image) => {
          this.article.images.push(image);
          this.leadImage = this.article.images[0].path;
        });
    }
  }

  onArticleFileChanged(event) {
    const imageLength = event.target.files.length;
    for (let i = 0; i < imageLength; i++) {
      this.detailsService
        .uploadArticleImage(event.target.files[i], this.id)
        .subscribe((image) => this.article.images.push(image));
    }
  }

  changeLeadImage(path: string) {
    this.leadImage = path;
  }
}
