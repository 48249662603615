import { NgModule } from '@angular/core';
import { HomeComponent } from './home.component';
import { SliderModule } from 'src/app/components/slider/slider.module';
import { CategoryListModule } from 'src/app/components/category-list/category-list.module';
import { FilterBannerModule } from 'src/app/components/filter-banner/filter-banner.module';
import { SpecialProductModule } from 'src/app/components/special-product/special-product.module';
import { SeasonBannerModule } from 'src/app/components/season-banner/season-banner.module';
import { PopularProductModule } from 'src/app/components/popular-product/popular-product.module';
import { ProductBannerModule } from 'src/app/components/product-banner/product-banner.module';

@NgModule({
    declarations: [HomeComponent],
    imports: [
        SliderModule,
        CategoryListModule,
        FilterBannerModule,
        SpecialProductModule,
        SeasonBannerModule,
        PopularProductModule,
        ProductBannerModule],
    providers: [],
})
export class HomeModule { }