export class Article {
    constructor(id?: number, name?: string, image?: string, price?: number, category?: KeyValuePair, description?: string) {

        this.id = id;
        this.name = name;
        this.image = image;
        this.price = price;
        this.category = category;
        this.description = description;

    }

    public id: number;
    public name: string;
    public image: string;
    public price: number;
    public category: KeyValuePair;
    public description: string;
    public additionalParams?: any;
    public quantity?: number;
    public stock?: number;
    public partCategoryId?: number;
    public images?: DalImage[];

}


export class ArticleModel {
    constructor(id?: number, name?: string, image?: string, price?: number, category?: string,
                description?: string, ean?: string,
                quantity?: number, stock?: number) {
      this.id = id;
      this.name = name;
      this.image = image;
      this.price = price;
      this.category = category;
      this.description = description;
      this.quantity = quantity;
      this.ean = ean;
      this.stock = stock;

    }

    public id: number;
    public name: string;
    public image: string;
    public price: number;
    public category: string;
    public description: string;
    public quantity: number;
    public ean: string;
    public stock: number;

  }

interface KeyValuePair {
    key?: number;
    value?: string;
}

export interface DalImage {
    id: number;
    path: string;
}
