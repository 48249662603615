import { Component, OnInit, Inject } from "@angular/core";
import { OrderModel } from "src/app/models/order";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Globals } from "src/app/services/global-variable.services";
import { CartModel } from "src/app/models/cart";
import { OrdersService } from "src/app/services/order.services";

@Component({
  selector: "laco-order-modal",
  templateUrl: "./order-modal.component.html",
  styleUrls: ["./order-modal.component.scss"],
})
export class OrderModalComponent implements OnInit {
  orderForm: FormGroup;
  orderModel: OrderModel;
  error: "";
  buttonDisabled = false;

  constructor(
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<OrderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: OrderModel,
    private orderService: OrdersService,
    private globals: Globals
  ) {
    this.orderModel = data;
  }

  ngOnInit() {
    this.orderForm = this._formBuilder.group({
      address: ["", Validators.required],
      city: ["", Validators.required],
      country: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required]],
      notice: [""],
    });
  }

  get orderFormData() {
    return this.orderForm.controls;
  }

  onSubmit(): void {
    this.buttonDisabled = true;
    this.orderModel.address = this.orderFormData.address.value;
    this.orderModel.city = this.orderFormData.city.value;
    this.orderModel.country = this.orderFormData.country.value;
    this.orderModel.phoneNumber = this.orderFormData.phoneNumber.value;
    this.orderModel.notice = this.orderFormData.notice.value;
    this.orderService.addOrder(this.orderModel).subscribe(
      (order) => {
        this.globals.addOrder(order as OrderModel);
        this.dialogRef.close(order);
      },
      (error) => {
        this.error = error;
        this.buttonDisabled = false;
      }
    );
  }

  totalPrice(products: CartModel[]) {
    let sum = 0;
    products.forEach((product) => {
      sum += product.price * product.quantity;
    });
    return sum;
  }
}
