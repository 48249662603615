import { Component, OnInit } from '@angular/core';
import { Part } from 'src/app/models/part.model';
import { PageEvent } from '@angular/material';
import { Globals } from 'src/app/services/global-variable.services';
import { ChainService } from 'src/app/services/chain.service';
import { CartModel, CartType } from 'src/app/models/cart';

@Component({
  selector: 'laco-chains',
  templateUrl: './chains.component.html',
  styleUrls: ['./chains.component.scss']
})
export class ChainsComponent implements OnInit {

  length = 100;
  pageSize = 10;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  chains: Part[] = [];
  chainLenghts: any[] = [
    {from: 30, to: 70, page: 1, pageSize: 10, checked: true},
    {from: 71, to: 100, page: 1, pageSize: 10},
    {from: 101, to: 120, page: 1, pageSize: 10},
    {from: 121, to: 160, page: 1, pageSize: 10}
  ];

   // MatPaginator Output
   pageEvent: PageEvent;

  selectedLenght: any;
  constructor(private globals: Globals, private chainService: ChainService) {

  }

  ngOnInit() {
    this.chainService.onChainLenghtChanged
    .subscribe(length => {
        this.length = length;
    });

    this.chainService.onChainsChanged
    .subscribe(chains => {
        this.chains = chains;
    });

    this.selectedLenght = {from: 30, to: 70, page: 1, pageSize: 10};
    this.globals.setActiveMenu('Lanci');
  }

  changeFilterLenght(lenght: any): void {
    this.selectedLenght = lenght.value;
    this.chainService.getChainsLenght(this.selectedLenght);
    this.chainService.getChains(this.selectedLenght);
  }

  discauntQuantity(part) {
    if (part.quantity > 0) {
      part.quantity--;
    }
  }

  addQuantity(part) {
    if (part.quantity < part.unitsInStock) {
      part.quantity++;
    }
  }

  addToBasket(part: Part) {
    const cartItem = new CartModel(part.id, part.productCategoryName, part.name, part.sellingPrice, part.quantity,
      part.image, CartType.Part);
    this.globals.addToCart(cartItem);
  }

  removeFromCart(part) {
    this.globals.removeFromCart(part);
  }

  getChains(event: PageEvent) {
    this.selectedLenght.page = event.pageIndex;
    this.chainService.getChains(this.selectedLenght);
  }

}
