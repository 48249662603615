
export class BikeFilter {
  constructor(brand?: string, model?: string, cc?: number, year?: number, type?: number) {

    this.brand = brand;
    this.model = model;
    this.cc = cc;
    this.year = year;
    this.type = type;
  }

  public brand: string;
  public model: string;
  public cc: number;
  public year: number;
  public type: number;
}
