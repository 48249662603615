import { Resolve } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ChainService implements Resolve<any> {
  rootPath = '';
  onChainsChanged: BehaviorSubject<any>;
  onChainLenghtChanged: BehaviorSubject<any>;

  chains: any[];
  chainLenght: number;
  chainLenghtInfo = {from: 30, to: 70, page: 1, pageSize: 10};

  constructor(
    private _httpClient: HttpClient
  ) {
    // Set the defaults
    this.onChainsChanged = new BehaviorSubject([]);
    this.onChainLenghtChanged = new BehaviorSubject([]);
  }

  resolve(): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {

      Promise.all([
        this.getChainsLenght(this.chainLenghtInfo),
        this.getChains(this.chainLenghtInfo)
      ]).then(
        ([]) => {
          resolve();
        },
        reject
      );
    });
  }

  getChains(chainLenght: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this.rootPath +
        `/api/Chain/GetChains?from=${chainLenght.from}&to=${chainLenght.to}&page=${chainLenght.page}&pageSize=${chainLenght.pageSize}`)
        .subscribe((chains: any) => {
          this.chains = chains;
          this.onChainsChanged.next(this.chains);
          resolve(this.chains);
        }, reject);
    }
    );
  }

  getChainsLenght(chainLenght: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(this.rootPath +
        `/api/Chain/GetChainsLenght?from=${chainLenght.from}&to=${chainLenght.to}`)
        .subscribe((chainLenghtResult: number) => {
          this.chainLenght = chainLenghtResult;
          this.onChainLenghtChanged.next(this.chainLenght);
          resolve(this.chains);
        }, reject);
    }
    );
  }
}
