import { Component, OnInit } from '@angular/core';
import { OrderModel } from 'src/app/models/order';
import { MatDialog } from '@angular/material';
import { CartModel } from 'src/app/models/cart';
import { OrderDetailModalComponent } from './order-detail-modal/order-detail-modal.component';
import { OrdersService } from 'src/app/services/order.services';
import { Globals } from 'src/app/services/global-variable.services';

@Component({
  selector: 'laco-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  orders: OrderModel[] = [];
  status = {
    CANCEL: 'Otkazana',
    SHIPPING: 'Poslana',
    FINISHED: 'Završena',
    PADDING: 'Napravljena'
  };

  constructor(private ordersService: OrdersService,  private dialog: MatDialog, private globals: Globals) {
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    this.ordersService.onOrdersChanged
    .subscribe(orders => {
        this.orders = orders;
    });
  }

  orderTotalPrice(products: CartModel[]): number {
    let result = 0;
    products.forEach(product => {
      result += (product.price * product.quantity);
    });
    return result;
  }

  showProducts(products: CartModel[]): void {
    const dialogRef = this.dialog.open(OrderDetailModalComponent, {
      width: '500px',
      data: products
    });
    dialogRef.afterClosed().subscribe(() => {
    });
  }


  submitReservation(orderId: number): void {
    this.ordersService.submitReservation(orderId);
  }

  cancleReservation(orderId: number): void {
    this.ordersService.cancleReservation(orderId);
  }

  isAdmin(): boolean {
    if (this.globals.getUser()) {
      return (this.globals.getUser().roles || []).indexOf('administrator') > -1;
    }
    return false;
  }
}
