import { NgModule } from '@angular/core';
import { AdminPageComponent } from './admin-page.component';
import { MatTabsModule } from '@angular/material';
import { ProductListModule } from 'src/app/components/product-list/product-list.module';

@NgModule({
    declarations: [AdminPageComponent],
    imports: [MatTabsModule, ProductListModule],
    providers: [],
})
export class AdminPageModule { }