import { NgModule } from '@angular/core';
import { PopularProductComponent } from './popular-product.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SpecialProductService } from '../special-product/special-product.service';

@NgModule({
    declarations: [PopularProductComponent],
    exports: [PopularProductComponent],
    imports: [CommonModule, RouterModule],
    providers: [SpecialProductService],
  })
  export class PopularProductModule { }