import { NgModule } from '@angular/core';
import { ProductBannerComponent } from './product-banner.component';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [ProductBannerComponent],
    exports: [ProductBannerComponent],
    imports: [RouterModule],
    providers: [],
  })
  export class ProductBannerModule { }