import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable()
export class ArticlesService implements Resolve<any> {
    rootPath = '';
    onArticleChanged: BehaviorSubject<any>;
    articles: any[];
    routeParams: any;

    constructor(
        private _httpClient: HttpClient,
    ) {
        // Set the defaults
        this.onArticleChanged = new BehaviorSubject([]);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getArticles(),
            ]).then(
                ([]) => {
                    resolve();
                },
                reject
            );
        });
    }

    getArticles(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.get(this.rootPath + '/api/ArticleCategory/Articles/' + this.routeParams.id)
                .subscribe((response: any) => {
                    this.articles = response;
                    this.onArticleChanged.next(this.articles);
                    resolve(this.articles);
                }, reject);
        }
        );
    }
}
