import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/services/global-variable.services';
import { CartModel } from 'src/app/models/cart';
import { MatDialog } from '@angular/material';
import { OrdersService } from 'src/app/services/order.services';
import { OrderModel } from 'src/app/models/order';
import { OrderModalComponent } from 'src/app/components/shared/order-modal/order-modal.component';
import { RegisterModalComponent } from 'src/app/components/shared/register-modal/register-modal.component';

@Component({
  selector: 'laco-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  title = 'cart';
  cart: CartModel[];
  message = 'Nemate proizvoda u korpi';
  constructor(private globals: Globals, private dialog: MatDialog, private ordersService: OrdersService) {
  }

  ngOnInit() {
    this.globals.setActiveMenu('LacoMoto');
    this.globals.onCartChanged
    .subscribe(cart => {
        this.cart = cart;
    });
  }

  createOrder() {
    const user = this.globals.getUser();
    if (user) {
      const order = new OrderModel();
      order.userId = user.id;
      order.products = this.cart;
      order.name = user.fullName;
      order.email = user.email;
      this.submitOrderDialog(order);
    } else {
      this.registerModal();
    }
  }

  removeFromCart(item: CartModel) {
    this.globals.removeFromCart(item);
  }

  registerModal(): void {
    const dialogRef = this.dialog.open(RegisterModalComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(filter => {
      this.createOrder();
    });
  }

  submitOrderDialog(order: OrderModel): void {
    const dialogRef = this.dialog.open(OrderModalComponent, {
      width: '500px',
      data: order
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.message = 'Uspješno ste poslali narudžbu';
        this.globals.resetCart();
        this.cart = [];
      }
    });
  }

  getOrders(): OrderModel[] {
    return this.ordersService.orders;
  }


}
