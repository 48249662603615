import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChainsComponent } from './chains.component';
import { ChainService } from 'src/app/services/chain.service';
import { MatPaginatorModule, MatRadioModule } from '@angular/material';
import { ProductBannerModule } from 'src/app/components/product-banner/product-banner.module';

@NgModule({
    declarations: [ChainsComponent],
    imports: [CommonModule, MatPaginatorModule, MatRadioModule, ProductBannerModule],
    providers: [ChainService],
})
export class ChainsModule {}