import { CartModel } from './cart';

export class OrderModel {
    public id: number;
    public name: string;
    public email: string;
    public phoneNumber: string;
    public userId: string;
    public address: string;
    public city: string;
    public country: string;
    public products: CartModel[];
    public notice: string;
    public status: string;

    public isPadding(): boolean {
        return this.status === 'PADDING';
    }
}
