import { NgModule } from '@angular/core';
import { SliderComponent } from './slider.component';
import { SliderService } from './slider.service';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [SliderComponent],
    imports: [CommonModule],
    exports: [SliderComponent],
    providers: [SliderService],
  })
  export class SliderModule { }