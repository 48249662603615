import { Component, OnInit } from '@angular/core';
import { NewsItemModel } from 'src/app/models/news';
import { NewsService } from 'src/app/services/news.service';

@Component({
  selector: 'laco-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.scss']
})
export class NewsItemComponent implements OnInit {
  title = 'Novosti';
  item: NewsItemModel = {id: 0, title: '', text: '', image: ''};

  constructor(private newsService: NewsService) {
  }

  ngOnInit() {
    this.newsService.onItemChanged
    .subscribe(item => {
        this.item = item;
    });
  }
}
