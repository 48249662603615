import { Component, OnInit } from '@angular/core';
import { UserRegisterModel, User } from 'src/app/models/user';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material';
import { Globals } from 'src/app/services/global-variable.services';
import { UserService } from 'src/app/services/user.service';
import { takeUntil} from 'rxjs/operators';


@Component({
  selector: 'laco-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.scss']
})
export class RegisterModalComponent implements OnInit {

  userRegister: UserRegisterModel;
  registerForm: FormGroup;
  loginForm: FormGroup;
  private _unsubscribeAll: Subject<any>;
  error = '';
  isRegister = false;
  isSubmitDisabled = false;
  constructor(private _formBuilder: FormBuilder, public dialogRef: MatDialogRef<RegisterModalComponent>,
              private authService: UserService,
              private globals: Globals) {
    this._unsubscribeAll = new Subject();
  }

  ngOnInit() {
    this.registerForm = this._formBuilder.group({
      userName: ['', Validators.required],
      fullName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
    });

    this.loginForm = this._formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });


    // Update the validity of the 'passwordConfirm' field
    // when the 'password' field changes
    this.registerForm.get('password').valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this.registerForm.get('passwordConfirm').updateValueAndValidity();
      });
  }

  // convenience getter for easy access to form fields
  get formData() { return this.registerForm.controls; }
  get loginFormData() { return this.loginForm.controls; }


  onRegister(): void {
    this.isSubmitDisabled = true;
    this.error = '';
    this.userRegister = new UserRegisterModel(this.formData.userName.value,
      this.formData.fullName.value,
      this.formData.email.value,
      this.formData.password.value,
      ['user']);

    this.authService.register(this.userRegister).subscribe(user => {
      this.globals.setUser( user as User);
      this.dialogRef.close({
      });
    },
      error => {
        this.isSubmitDisabled = false;
        if (error.error.NewPassword) {
          this.error = 'Lozinka mora biti veća od 6 karaktera.';
        } else if (error.error[''][0].indexOf('User name') > -1) {
          this.error = 'Korisničko ime se već koristi.';
        } else if (error.error[''][0].indexOf('Email') > -1) {
          this.error = 'Email se već koristi.';
        }
      });

  }

  onLogin(): void {
    this.isSubmitDisabled = true;
    this.authService.login(this.loginFormData.userName.value, this.loginFormData.password.value, true).subscribe(data => {
      this.globals.setToken(data);
      this.authService.getCurrentUser().subscribe(user => {
        this.globals.setUser( user as User);
        this.dialogRef.close({
        });
      });
    },
    error => {
      this.isSubmitDisabled = false;
      this.error = 'Korisnički podaci nisu validni';
    });
  }

  changeForm(register: boolean): boolean {
    this.isRegister = !register;
    return this.isRegister;
  }
}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if (!control.parent || !control) {
    return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if (!password || !passwordConfirm) {
    return null;
  }

  if (passwordConfirm.value === '') {
    return null;
  }

  if (password.value === passwordConfirm.value) {
    return null;
  }

  return { passwordsNotMatching: true };

};
