interface Scripts {
    name: string;
    src: string;
}
export const ScriptStore: Scripts[] = [
    {
        name: 'jquery', src: '/js/jquery-1.12.3.min.js'
    },
    {
        name: 'fractionslider', src: '/js/jquery.fractionslider.min.js'
    }, {
        name: 'main', src: '/js/main.js'
    },
    {
        name: 'fancybox', src: '/js/fancybox/fancybox.js'
    },
    {
        name: 'slider', src: '/js/slider.js'
    },
    {
        name: 'filter', src: '/js/filter.js'
    }
];
