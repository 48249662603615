import { Component, OnInit } from '@angular/core';

import { Inject } from '@angular/core';
import { BikeFilter } from 'src/app/models/bike-filter';
import { BikeService } from 'src/app/services/bike.services';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export class DialogData {
  filter: BikeFilter;
  bikes: BikeFilter[];
}


@Component({
  selector: 'laco-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss']
})
export class FilterModalComponent implements OnInit {
  model: string;
  brand: string;
  cc: number;
  year: number;
  bikesinput: BikeFilter[];
  bikeFilter: BikeFilter = new BikeFilter();

  bikeBrands: string[] = [];
  bikeModels: string[] = [];
  bikeCcs: number[] = [];
  bikeYears: number[] = [];
  bikes: number[] = [];

  constructor(private bikeService: BikeService,
              public dialogRef: MatDialogRef<FilterModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.model = data.filter.model;
    this.brand = data.filter.brand;
    this.cc = data.filter.cc;
    this.year = data.filter.year;
    this.bikesinput = data.bikes;
  }

  ngOnInit() {
    this.loadBrand();
  }

  onOkClick(): void {
    this.dialogRef.close({
      model: this.model,
      brand: this.brand,
      cc: this.cc,
      year: this.year
    });
  }

  filterBrands(brand) {
    this.bikeFilter = new BikeFilter(brand);
    this.model = null;
    this.cc = null;
    this.loadCc();
  }

  filterModels(model) {
    this.bikeFilter.model = model;
    this.bikeFilter.year = null;
    this.loadYear();
  }

  filterCcs(cc) {
    this.bikeFilter.cc = cc;
    this.bikeFilter.model = null;
    this.cc = null;
    this.loadModel();
  }

  filterYears(year) {
    this.bikeFilter.year = year;
  }

  loadBrand() {
    this.bikeBrands = Array.from(new Set(this.bikesinput.map(bike => bike.brand)));
    this.bikeFilter.brand = this.bikeBrands.find(brand => this.brand === brand) || this.bikeBrands[0];
    this.brand = this.bikeFilter.brand;
    this.loadCc();
  }

  loadCc() {
    this.bikeCcs = Array.from(new Set(this.bikesinput.filter(bike => bike.brand === this.bikeFilter.brand && bike.cc > 0)
    .map(bike => bike.cc)));

    this.bikeFilter.cc = this.bikeCcs.find(cc => cc === this.cc) || this.bikeCcs[0];
    this.loadModel();
  }

  loadModel() {
    this.bikeModels = Array.from(new Set(this.bikesinput.filter(bike => bike.brand === this.bikeFilter.brand
      && bike.cc === this.bikeFilter.cc).map(bike => bike.model)));
    this.bikeFilter.model = this.bikeModels.find(model => model === this.model) || this.bikeModels[0];
    this.model = this.bikeFilter.model;
    if (this.bikeModels.length) {
      this.cc = this.bikeFilter.cc;
      this.loadYear();
    }
  }


  loadYear() {
    const years = this.bikesinput.filter(bike => bike.brand == this.bikeFilter.brand
      && bike.model == this.bikeFilter.model && bike.cc == this.bikeFilter.cc && bike.year > 0).map(bike => bike.year);
    this.bikeYears = Array.from(new Set(years));
    if (this.bikeYears.length) {
      this.year = this.bikeYears.find(year => year == this.year) || this.bikeYears[0];
      this.bikeFilter.year = this.bikeYears.find(year => year == this.year) || this.bikeYears[0];
    }
  }
}
