import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CartModel } from "../models/cart";
import { HeaderModel } from "../models/header";
import { BikeFilter } from "../models/bike-filter";
import { OrderModel } from "../models/order";
import { User } from "../models/user";

@Injectable({
  providedIn: "root",
})
export class Globals {
  cart: CartModel[] = [];
  onCartChanged: BehaviorSubject<any>;
  menu: HeaderModel[] = [
    new HeaderModel("OPREMA", "has-child", true, "", [
      {
        name: "Garderoba",
        link: "/article/category/1",
        group: 1,
      },
      {
        name: "Kacige",
        link: "/article/category/2",
        group: 1,
      },
      {
        name: "Obuća",
        link: "/article/category/3",
        group: 1,
      },
      {
        name: "Kišna odijela",
        link: "/article/category/17",
        group: 1,
      },
      {
        name: "Naočale",
        link: "/article/category/27",
        group: 1,
      },
    ]),
    new HeaderModel("DIJELOVI", "has-child", true, "", [
      {
        name: "Akumulatori",
        link: "/article/category/5",
        group: 1,
      },
      {
        name: "Svjećice",
        link: "/article/category/6",
        group: 1,
      },
      {
        name: "Lanci i lančanici",
        link: "/article/category/8",
        group: 1,
      },
      {
        name: "Semering",
        link: "/article/category/9",
        group: 1,
      },
      {
        name: "Pakne / Disk pločice",
        link: "/article/category/11",
        group: 1,
      },
      {
        name: "Filteri - Ulje / zrak",
        link: "/article/category/13",
        group: 1,
      },
      {
        name: "Lamele",
        link: "/article/category/14",
        group: 1,
      },
      {
        name: "Upravljači",
        link: "/article/category/15",
        group: 1,
      },
      {
        name: "Žarulje",
        link: "/article/category/18",
        group: 1,
      },
      {
        name: "Ručke",
        link: "/article/category/19",
        group: 1,
      },
      {
        name: "Poluga mjenjača",
        link: "/article/category/20",
        group: 1,
      },
      {
        name: "Ručice gasa",
        link: "/article/category/21",
        group: 1,
      },
      {
        name: "Oslonac / držač nogu",
        link: "/article/category/22",
        group: 1,
      },
      {
        name: "Ručice kočnice",
        link: "/article/category/23",
        group: 1,
      },
      {
        name: "Ručice kvačila",
        link: "/article/category/24",
        group: 1,
      },
      {
        name: "Držači gume",
        link: "/article/category/26",
        group: 1,
      },
      {
        name: "Gume za motocikle",
        link: "/article/category/7",
        group: 1,
      },
      //TODO: naći grupe artikala
      {
        name: "Gume za auta",
        link: "/article/category/26",
        group: 1,
      },
    ]),
    new HeaderModel("OSTALO", "has-child", true, "", [
      {
        name: "Ulja, Maziva, Aditivi",
        link: "/article/category/4",
        group: 1,
      },
      {
        name: "Protektori / Torbe / Bisage",
        link: "/article/category/10",
        group: 1,
      },
      {
        name: "Kamere i Bluetooth",
        link: "/article/category/12",
        group: 1,
      },
      {
        name: "Punjači",
        link: "/article/category/16",
        group: 1,
      },
      {
        name: "Alati",
        link: "/article/category/25",
        group: 1,
      },
      {
        name: "Ram za tablice",
        link: "/article/category/28",
        group: 1,
      },
    ]),
    new HeaderModel("AFAM", "has-child", true, "", [
      {
        name: "Dijelovi",
        link: "/parts",
        group: 1,
      },
      {
        name: "Lancanici",
        link: "/chains",
        group: 1,
      },
    ]),
    new HeaderModel("KONTAKT", "", false, "/contact", []),
  ];
  filter: BikeFilter;
  orders: OrderModel[] = [];

  constructor() {
    this.onCartChanged = new BehaviorSubject([]);
  }

  addToCart(item: CartModel) {
    this.cart.push(item);
    localStorage.setItem("cart", JSON.stringify(this.cart));
    this.onCartChanged.next(this.cart);
  }

  removeFromCart(item) {
    this.cart.splice(this.cart.indexOf(item), 1);
    localStorage.setItem("cart", JSON.stringify(this.cart));
    this.onCartChanged.next(this.cart);
  }

  resetCart() {
    this.cart = [];
    localStorage.setItem("cart", JSON.stringify(this.cart));
    this.onCartChanged.next(this.cart);
  }

  setFilter(filter: BikeFilter) {
    this.filter = filter;
  }

  getFilter(): BikeFilter {
    return this.filter;
  }

  getCart(): void {
    if (this.cart.length === 0) {
      this.cart = JSON.parse(localStorage.getItem("cart")) || [];
    }
    this.onCartChanged.next(this.cart);
  }

  getMenu(): HeaderModel[] {
    return this.menu;
  }

  setActiveMenu(name: string) {
    this.menu.forEach((item) => (item.className = ""));
    const menuItem = this.menu.find((item) => item.name === name) || {
      className: "",
    };
    menuItem.className = "active";
  }

  getUser(): User {
    return JSON.parse(localStorage.getItem("user"));
  }

  setUser(user: User) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  setToken(token) {
    localStorage.setItem("token", JSON.stringify(token));
  }

  getToken() {
    return JSON.parse(localStorage.getItem("token"));
  }

  addOrder(order: OrderModel): void {
    this.orders.push(order);
    localStorage.setItem(this.getUser().id, JSON.stringify(this.orders));
    this.onCartChanged.next(this.orders);
  }

  singOut() {
    localStorage.setItem("token", null);
    localStorage.setItem("user", null);
  }

  isAdmin(): boolean {
    if (this.getUser()) {
      return (this.getUser().roles || []).indexOf("administrator") > -1;
    }
    return false;
  }
}
