import { NgModule } from '@angular/core';
import { FilterModalComponent } from './filter-modal.component';
import { MatFormFieldModule, MatSelectModule, MatDialogModule, MatButtonModule } from '@angular/material';
import { BikeService } from 'src/app/services/bike.services';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [FilterModalComponent],
    imports: [MatFormFieldModule, MatSelectModule, CommonModule, MatDialogModule, MatButtonModule],
    providers: [BikeService],
    exports: [FilterModalComponent],
    entryComponents: [FilterModalComponent]
})
export class FilterModalModule {}