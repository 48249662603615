import { NgModule } from '@angular/core';
import { BikeService } from 'src/app/services/bike.services';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatCheckboxModule, MatButtonModule, MatInputModule } from '@angular/material';
import { RegisterModalComponent } from './register-modal.component';

@NgModule({
    declarations: [RegisterModalComponent],
    imports: [CommonModule,
        FormsModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatCheckboxModule,
        MatButtonModule,
        MatInputModule],
    providers: [BikeService],
    exports: [RegisterModalComponent],
    entryComponents: [RegisterModalComponent]
})
export class RegisterModalModule {}