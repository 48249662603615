import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PartDetailsComponent } from './part-details.component';
import { DetailModule } from 'src/app/components/shared/detail/detail.module';
import { DetailsService } from 'src/app/services/detail.services';
import { MatButtonModule } from '@angular/material';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [PartDetailsComponent],
    imports: [CommonModule, DetailModule, MatButtonModule, CKEditorModule, FormsModule],
    providers: [DetailsService]
})
export class PartDetailsModule {}