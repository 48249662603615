import { Component, OnInit } from '@angular/core';
import { BikeFilter } from 'src/app/models/bike-filter';
import { Router } from '@angular/router';
import { Globals } from 'src/app/services/global-variable.services';
import { BikeService } from 'src/app/services/bike.services';

@Component({
  selector: 'laco-filter-banner',
  templateUrl: './filter-banner.component.html',
  styleUrls: ['./filter-banner.component.scss']
})
export class FilterBannerComponent implements OnInit {
  title = 'laco-app';
  model: string = null;
  brand: string;
  cc: number = null;
  year: number = null;
  type: number = null;
  bikesinput: BikeFilter[] = [];
  bikeFilter: BikeFilter = new BikeFilter();
  bikeBrands: string[] = [];
  bikeModels: string[] = [];
  bikeCcs: number[] = [];
  bikeYears: number[] = [];
  bikes: number[] = [];
  seats: number;
  hendsControls: number;
  windShields: number;
  litghtings: number;
  wheels: number;
  tires: number;
  filters: number;
  engines: number;
  breakeShoes: number;
  exhausts: number;


  constructor(private bikeService: BikeService, private globals: Globals, private router: Router) { }
  ngOnInit() {
    const filter = this.globals.getFilter();
    if (filter) {
      this.brand = filter.brand;
      this.model = filter.model;
      this.cc = filter.cc;
      this.year = filter.year;
    } else {
      this.brand = 'PIAGGIO';
    }


    this.setFilterDefaultValues();
    this.loadBikes();
  }

  setFilterDefaultValues() {
  }

  loadBikes() {
    const bikesLocal = JSON.parse(localStorage.getItem('bikes'));
    if (bikesLocal) {
      this.bikesinput = bikesLocal;
      this.loadBrand();
    } else {
      this.bikeService.getAllBikes()
      .subscribe((bikes: BikeFilter[]) => {
        this.bikesinput = bikes;
        localStorage.setItem('bikes', JSON.stringify(bikes));
        this.loadBrand();
      });
    }
  }


  filterBrands(brand) {
    this.bikeFilter = new BikeFilter(brand);
    this.model = null;
    this.cc = null;
    this.year = null;
    this.loadCc();
  }

  filterModels(model) {
    this.bikeFilter.model = model;
    this.bikeFilter.year = null;
    this.loadYear();
  }

  filterCcs(cc) {
    this.bikeFilter.cc = cc;
    this.bikeFilter.model = null;
    this.loadModel();
  }

  filterYears(year) {
    this.bikeFilter.year = year;
    this.loadBikePartsGroupedByType();
  }

  loadBrand() {
    this.bikeBrands = Array.from(new Set(this.bikesinput.map(bike => bike.brand)));
    this.bikeFilter.brand = this.bikeBrands.find(brand => this.brand === brand) || this.bikeBrands[0];
    this.brand = this.bikeFilter.brand;
    this.loadCc();
  }

  loadCc() {
    this.bikeCcs = Array.from(new Set(this.bikesinput.filter(bike => bike.brand === this.bikeFilter.brand && bike.cc > 0)
    .map(bike => bike.cc)));

    this.bikeFilter.cc = this.bikeCcs.find(cc => cc === this.cc) || this.bikeCcs[0];
    this.loadModel();
  }

  loadModel() {
    this.bikeModels = Array.from(new Set(this.bikesinput.filter(bike => bike.brand === this.bikeFilter.brand
      && bike.cc === this.bikeFilter.cc).map(bike => bike.model)));
    this.bikeFilter.model = this.bikeModels.find(model => model === this.model) || this.bikeModels[0];
    this.model = this.bikeFilter.model;
    if (this.bikeModels.length) {
      this.cc = this.bikeFilter.cc;
      this.loadYear();
    } else {
      this.loadBikePartsGroupedByType();
    }
  }

  loadBikePartsGroupedByType() {
    this.bikeService.getBikePartsGroupedByType(this.bikeFilter).subscribe((grupedTypes: KeyValuePair[]) => {
      if (grupedTypes.length) {
        this.seats = (grupedTypes.find(type => type.key == 0) || {}).value;
        this.hendsControls = (grupedTypes.find(type => type.key == 1) || {}).value;
        this.windShields = (grupedTypes.find(type => type.key == 2) || {}).value;
        this.litghtings = (grupedTypes.find(type => type.key == 3) || {}).value;
        this.wheels = (grupedTypes.find(type => type.key == 4) || {}).value;
        this.tires = (grupedTypes.find(type => type.key == 5) || {}).value;
        this.filters = (grupedTypes.find(type => type.key == 6) || {}).value;
        this.engines = (grupedTypes.find(type => type.key == 7) || {}).value;
        this.breakeShoes = (grupedTypes.find(type => type.key == 8) || {}).value;
        this.exhausts = (grupedTypes.find(type => type.key == 9) || {}).value;
      }
    });
  }

  loadYear() {
    const years = this.bikesinput.filter(bike => bike.brand == this.bikeFilter.brand
      && bike.model == this.bikeFilter.model && bike.cc == this.bikeFilter.cc && bike.year > 0).map(bike => bike.year);
    this.bikeYears = Array.from(new Set(years));
    if (this.bikeYears.length) {
      this.year = this.bikeYears.find(year => year == this.year) || this.bikeYears[0];
      this.bikeFilter.year = this.bikeYears.find(year => year == this.year) || this.bikeYears[0];
      this.loadBikePartsGroupedByType();
    }
    this.globals.setFilter(new BikeFilter(this.brand, this.model, this.cc, this.year, this.type));
  }

  openParts(type: any, count: any) {
    if (count > 0) {
      this.type = type;
      this.globals.setFilter(new BikeFilter(this.brand, this.model, this.cc, this.year, this.type));
      this.router.navigateByUrl('/parts');
    }
  }
}

interface KeyValuePair {
  key?: number;
  value?: number;
}
