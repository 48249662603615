import { Component, OnInit } from '@angular/core';
import { BikeFilter } from 'src/app/models/bike-filter';
import { MatDialog } from '@angular/material';
import { BikeService } from 'src/app/services/bike.services';
import { Globals } from 'src/app/services/global-variable.services';
import { CartModel, CartType } from 'src/app/models/cart';
import { PartCategoryFilter } from 'src/app/models/part-category-filter';
import { PartCategory } from 'src/app/models/part-category';
import { Part } from 'src/app/models/part.model';
import { PartService } from 'src/app/services/part.service';
import { FilterModalComponent } from 'src/app/components/shared/filter-modal/filter-modal.component';

@Component({
  selector: 'laco-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.scss']
})
export class PartsComponent implements OnInit {

  model: string;
  brand: string;
  cc: number;
  year: number;
  type: number;
  bikesinput: BikeFilter[] = [];
  isSpinnerShown = true;
  filter: BikeFilter = new BikeFilter(this.brand, this.model, this.cc, this.year, this.type);
  bikeFilter: BikeFilter = new BikeFilter();
  bikeBrands: string[] = [];
  bikeModels: string[] = [];
  bikeCcs: number[] = [];
  bikeYears: number[] = [];
  bikes: number[] = [];
  partCategoryFilter: PartCategoryFilter = {
    categories: [],
    brand: null,
    cc: null,
    model: null,
    year: null,
    type: null
  };

  partCategories: PartCategory[] = [];

  parts: Part[] = [];
  currentParts: Part[] = [];


  constructor(private dialog: MatDialog, private bikeService: BikeService,
              private partService: PartService, private globals: Globals) {

  }

  ngOnInit() {
    this.setDefaultFilterValues();
    this.loadBikes();
    this.globals.setActiveMenu('Dijelovi');
  }

  setDefaultFilterValues() {
    const filter = this.globals.getFilter() || new BikeFilter('APRILIA');
    this.model = filter.model;
    this.brand = filter.brand;
    this.cc = filter.cc;
    this.year = filter.year;
    this.type = filter.type;
  }

  loadBikes() {
    const bikesLocal = JSON.parse(localStorage.getItem('bikes'));
    if (bikesLocal) {
      this.bikesinput = bikesLocal;
      this.loadBrand();
    } else {
      this.bikeService.getAllBikes()
      .subscribe((bikes: BikeFilter[]) => {
        this.bikesinput = bikes;
        localStorage.setItem('bikes', JSON.stringify(bikes));
        this.loadBrand();
      });
    }

  }
  openDialog(): void {
    const dialogRef = this.dialog.open(FilterModalComponent, {
      width: '300px',
      data: {
        filter: this.bikeFilter,
        bikes: this.bikesinput
      }
    });
    dialogRef.afterClosed().subscribe(filter => {
      if (filter) {
        this.bikeFilter = filter;
        this.brand = filter.brand;
        this.cc = filter.cc;
        this.model = filter.model;
        this.year = filter.year;
        this.type = null;
        this.isSpinnerShown = true;
        this.loadBrand();
        this.globals.setFilter(filter);
      }
    });
  }

  filterBrands(brand) {
    this.bikeFilter = new BikeFilter(brand);
    this.model = null;
    this.cc = null;
    this.loadModel();
  }

  filterModels(model) {
    this.bikeFilter.model = model;
    this.bikeFilter.cc = null;
    this.bikeFilter.year = null;
    this.loadCc();
  }

  filterCcs(cc) {
    this.bikeFilter.cc = cc;
    this.bikeFilter.year = null;
    this.cc = null;
    this.loadYear();
  }

  filterYears(year) {
    this.bikeFilter.year = year;
  }

  loadBrand() {
    this.bikeBrands = Array.from(new Set(this.bikesinput.map(bike => bike.brand)));
    this.bikeFilter.brand = this.bikeBrands.find(brand => this.brand == brand) || this.bikeBrands[0];
    this.brand = this.bikeFilter.brand;
    this.loadModel();
  }

  loadModel() {
    this.bikeModels = Array.from(new Set(this.bikesinput.filter(bike => bike.brand == this.bikeFilter.brand).map(bike => bike.model)));
    this.bikeFilter.model = this.bikeModels.find(model => model == this.model) || this.bikeModels[0];
    this.model = this.bikeFilter.model;
    this.loadCc();
  }

  loadCc() {
    this.bikeCcs = Array.from(new Set(this.bikesinput.filter(bike => bike.brand == this.bikeFilter.brand
      && bike.model == this.bikeFilter.model && bike.cc > 0).map(bike => bike.cc)));

    this.bikeFilter.cc = this.bikeCcs.find(cc => cc == this.cc) || this.bikeCcs[0];
    if (this.bikeCcs.length) {
      this.cc = this.bikeFilter.cc;
      this.loadYear();
    } else {
      this.loadProduct();
    }
  }

  loadYear() {
    const years = this.bikesinput.filter(bike => bike.brand == this.bikeFilter.brand
      && bike.model == this.bikeFilter.model && bike.cc == this.bikeFilter.cc && bike.year > 0).map(bike => bike.year);
    this.bikeYears = Array.from(new Set(years));
    if (this.bikeYears.length) {
      this.year = this.bikeYears.find(year => year == this.year) || this.bikeYears[0];
      this.bikeFilter.year = this.bikeYears.find(year => year == this.year) || this.bikeYears[0];
      this.loadProduct();
    }
  }

  loadProduct() {
    this.partCategoryFilter.brand = this.brand;
    this.partCategoryFilter.cc = this.cc;
    this.partCategoryFilter.model = this.model;
    this.partCategoryFilter.year = this.year;
    this.partCategoryFilter.type = this.type;
    const parts = this.partService.getParts(this.partCategoryFilter);
    parts
      // tslint:disable-next-line: no-shadowed-variable
      .subscribe(parts => {
        this.currentParts =  parts as Part[];

        this.partCategoryFilter.categories = [];
        this.currentParts.forEach(part => {
          if (this.partCategoryFilter.categories.filter(p => p.description === part.productCategoryName).length === 0) {
            this.partCategoryFilter.categories.push(new PartCategory(part.productCategoryName, part.type));
          }
        });
        this.partCategories = this.partCategoryFilter.categories;

        if (this.type == null) {
          this.partCategories[0].isChecked = true;
        } else {
          this.partCategories.filter(p => p.type == this.type).forEach(p => { p.isChecked = true; });
        }

        this.parts = this.currentParts.filter(part => this.partCategories
          .filter(c => c.isChecked)
          .map(c => c.description)
          .indexOf(part.productCategoryName) > -1);
        this.isSpinnerShown = false;
      },
        error => {
        });
  }

  getNumberofProduct(value: any[]) {
    return value.length;
  }

  changeFilterCategories(partCategory: PartCategory) {
    this.partCategoryFilter.categories = this.partCategories.filter(p => p.isChecked);
    this.parts = this.currentParts.filter(part =>
      this.partCategoryFilter.categories.map(p => p.description).indexOf(part.productCategoryName) > -1);
  }

  discauntQuantity(part) {
    if (part.quantity > 0) {
      part.quantity--;
    }
  }

  addQuantity(part) {
    if (part.quantity < part.unitsInStock) {
      part.quantity++;
    }
  }

  addToBasket(part: Part) {
    const cartItem = new CartModel(part.id, part.productCategoryName, part.name, part.sellingPrice, part.quantity,
      part.image, CartType.Part);
    this.globals.addToCart(cartItem);
  }

  removeFromCart(part) {
    this.globals.removeFromCart(part);
  }
}
