import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material';
import { OrderDetailModalComponent } from './order-detail-modal.component';

@NgModule({
    declarations: [OrderDetailModalComponent],
    imports: [CommonModule, MatDialogModule],
    exports: [OrderDetailModalComponent],
    entryComponents: [OrderDetailModalComponent]

})
export class OrderDetailModalModule { }