import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class SliderService {
  bannersUrl = '/api/Banner/Banners';

  constructor(private http: HttpClient) { }

  getBanners() {
    return this.http.get(this.bannersUrl);
  }

}
