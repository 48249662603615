import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PartsComponent } from './pages/parts/parts/parts.component';
import { PartDetailsComponent } from './pages/part-details/part-details.component';
import { ArticleDetailsComponent } from './pages/article-details/article-details.component';
import { ChainsComponent } from './pages/chains/chains.component';
import { ChainService } from './services/chain.service';
import { OrdersComponent } from './pages/orders/orders.component';
import { OrdersService } from './services/order.services';
import { CartComponent } from './pages/cart/cart.component';
import { ArticlesComponent } from './pages/articles/articles.component';
import { ArticlesService } from './services/article.service';
import { ContactComponent } from './pages/contact/contact.component';
import { PartnerComponent } from './pages/partner/partner.component';
import { NewsItemComponent } from './pages/news-item/news-item.component';
import { NewsService } from './services/news.service';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'parts', component: PartsComponent },
  { path: 'admin', component: AdminPageComponent },
  { path: 'part/:id', component: PartDetailsComponent },
  { path: 'article/:id', component: ArticleDetailsComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'partner', component: PartnerComponent },
   {
     path: 'cart', component: CartComponent, resolve: {
       articles: OrdersService,
  }
  },
  {
     path: 'orders', component: OrdersComponent, resolve: {
       articles: OrdersService,
    }
   },
   {
     path: 'article/category/:id', component: ArticlesComponent, resolve: {
       articles: ArticlesService,
     }
  },
  {
    path: 'news/item/:id', component: NewsItemComponent, resolve: {
       item: NewsService,
     }
   },
  {
     path: 'chains', component: ChainsComponent, resolve: {
       chains: ChainService,
  }},
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
