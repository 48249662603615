import { NgModule } from '@angular/core';
import { HeaderComponent } from './header.component';
import { MatMenuModule } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [HeaderComponent],
    exports: [HeaderComponent],
    imports: [MatMenuModule, CommonModule, RouterModule],
    providers: [],
  })
  export class HeaderModule { }