import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Resolve } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Globals } from './global-variable.services';
import { OrderModel } from '../models/order';

@Injectable()
export class OrdersService implements Resolve<any> {
  rootPath = '';
  onOrdersChanged: BehaviorSubject<any>;
  orders: any[];
  partsAddOrderUrl = this.rootPath + '/api/Order/AddOrder';

  constructor(private _httpClient: HttpClient, private globals: Globals) {
    // Set the defaults
    this.onOrdersChanged = new BehaviorSubject([]);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  resolve(): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([this.getOrders()]).then(([]) => {
        resolve();
      }, reject);
    });
  }

  getOrders(): Promise<any> {
    const user = this.globals.getUser() || { id: 0 };
    return new Promise((resolve, reject) => {
      if (user) {
        this._httpClient
          .get(this.rootPath + '/api/Order/GetUserOrders/' + user.id)
          .subscribe((orders: any) => {
            this.orders = orders;
            this.onOrdersChanged.next(this.orders);
            resolve(this.orders);
          }, reject);
      }
    });
  }

  cancleReservation(orderId: number) {
    this._httpClient
      .put(this.rootPath + '/api/Order/CancleReservation/' + orderId, {})
      .subscribe((orders: any) => {
        this.getOrders();
      });
  }

  submitReservation(orderId: number) {
    this._httpClient
      .put(this.rootPath + '/api/Order/FinishReservation/' + orderId, {})
      .subscribe((orders: any) => {
        this.getOrders();
      });
  }

  addOrder(order: OrderModel) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
      })
    };
    return this._httpClient.post(this.partsAddOrderUrl, order, httpOptions);
  }
}
