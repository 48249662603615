import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersComponent } from './orders.component';
import { OrdersService } from 'src/app/services/order.services';
import { MatDialogModule } from '@angular/material';
import { OrderDetailModalModule } from './order-detail-modal/order-detail.module';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [OrdersComponent],
    imports: [CommonModule, MatDialogModule, OrderDetailModalModule, RouterModule],
    providers: [OrdersService],
})
export class OrdersModule { }