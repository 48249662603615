import { NgModule } from '@angular/core';
import { ProductListComponent } from './product-list.component';
import { MatTableModule } from '@angular/material';

@NgModule({
    declarations: [ProductListComponent],
    exports: [ProductListComponent],
    imports: [MatTableModule],
    providers: [],
  })
  export class ProductListModule { }