export class HeaderModel {
    constructor(name: string,
                className: string,
                hasChildren: boolean,
                link: string,
                children: any[]) {
        this.name = name;
        this.className = className;
        this.hasChildren = hasChildren;
        this.link = link;
        this.children = children;
    }

    public name: string;
    public className: string;
    public hasChildren: boolean;
    public link: string;
    public children: any[];
}
