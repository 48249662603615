import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PartDescription } from '../models/part-description';
import { DalImage } from '../models/article';

@Injectable()
export class DetailsService {
  rootPath = '';
  partUrl = this.rootPath + '/api/part/Part/';
  articleUrl = this.rootPath + '/api/Article/Article/';

  partDescUpdate = this.rootPath + '/api/part/UpdateDescription';
  articleDescUpdate = this.rootPath + '/api/article/UpdateDescription';

  partAddImage = this.rootPath + '/api/part/AddImage';
  articleAddImage = this.rootPath + '/api/Article/AddImage';
  removeImageUrl = this.rootPath + '/api/part/RemoveImage';

  constructor(private http: HttpClient) { }

  getPart(id: number) {
    return this.http.get(this.partUrl + id);
  }

  getArticle(id: number) {
    return this.http.get(this.articleUrl + id);
  }

  updatePartDescription(model: PartDescription) {
    return this.http.post(this.partDescUpdate, model);
  }

  updateArticleDescription(model: PartDescription) {
    return this.http.post(this.articleDescUpdate, model);
  }


  uploadPartImage(image: File, id: number) {
    const uploadData = new FormData();
    uploadData.append('file', image, image.name);
    uploadData.append('partId', id.toString());
    return this.http.post<DalImage>(this.partAddImage, uploadData);
  }

  uploadArticleImage(image: File, id: number) {
    const uploadData = new FormData();
    uploadData.append('file', image, image.name);
    uploadData.append('ArticleId', id.toString());
    return this.http.post<DalImage>(this.articleAddImage, uploadData);
  }

  removeImage(id: number) {
    return this.http.delete(this.removeImageUrl + `?id=${id}`);
  }
}
