import { Component, OnInit, Inject } from '@angular/core';
import { CartModel } from 'src/app/models/cart';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'laco-order-detail-modal',
  templateUrl: './order-detail-modal.component.html',
  styleUrls: ['./order-detail-modal.component.scss']
})
export class OrderDetailModalComponent implements OnInit {

  products: CartModel[] = [];

  constructor(public dialogRef: MatDialogRef<OrderDetailModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: CartModel[]) {
    this.products = data;
  }

  ngOnInit() {
  }

  totalPrice(products: CartModel[]) {
    let sum = 0;
    products.forEach(product => {
      sum += (product.price * product.quantity);
    });
    return sum;
  }
}
