import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class SpecialProductService {
    specialOfferUrl = '/api/SpecialOffer/getSpecialOffer';
    popularUrl = '/api/SpecialOffer/getPopular';


  constructor(private http: HttpClient) { }

  getSpecialOffers() {
    return this.http.get(this.specialOfferUrl);
  }

  getPopular() {
      return this.http.get(this.popularUrl);
  }
}