import { Component, OnInit } from '@angular/core';
import { DetailComponent } from 'src/app/components/shared/detail/detail.component';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'laco-part-details',
  templateUrl: './part-details.component.html',
  styleUrls: ['./part-details.component.scss']
})
export class PartDetailsComponent extends DetailComponent implements OnInit {
  public Editor = ClassicEditor;
  isEditMode = false;
  ngOnInit() {
    super.getPart();
  }

  edit(): void {
    this.isEditMode = true;
  }

  save(): void {
    this.isEditMode = false;
    super.savePartDescription({id: this.id, description: this.article.description });
  }
}
