import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './pages/home/home.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderModule } from './components/header/header.module';
import { FooterModule } from './components/footer/footer.module';
import { PartsModule } from './pages/parts/parts/parts.module';
import { PartDetailsModule } from './pages/part-details/part-details.module';
import { ArticleDetailsModule } from './pages/article-details/article-details.module';
import { ChainsModule } from './pages/chains/chains.module';
import { ChainService } from './services/chain.service';
import { OrdersModule } from './pages/orders/orders.module';
import { CartModule } from './pages/cart/cart.module';
import { ArticlesModule } from './pages/articles/articles.module';
import { ContactModule } from './pages/contact/contact.module';
import { PartnerModule } from './pages/partner/partner.module';
import { NewsItemComponent } from './pages/news-item/news-item.component';
import { NewsItemModule } from './pages/news-item/news-item.module';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { AdminPageModule } from './pages/admin-page/admin-page.module';
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    HomeModule,
    BrowserAnimationsModule,
    HeaderModule,
    FooterModule,
    PartsModule,
    PartDetailsModule,
    ArticleDetailsModule,
    ChainsModule,
    OrdersModule,
    CartModule,
    ArticlesModule,
    ContactModule,
    PartnerModule,
    AdminPageModule,
    NewsItemModule
  ],
  providers: [ChainService],
  bootstrap: [AppComponent]
})
export class AppModule { }
