import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { RouterModule } from '@angular/router';


@NgModule({
    declarations: [FooterComponent],
    exports: [FooterComponent],
    imports: [RouterModule],
    providers: [],
  })
  export class FooterModule { }