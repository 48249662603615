import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderModalComponent } from './order-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule, MatButtonModule } from '@angular/material';
import { OrdersService } from 'src/app/services/order.services';
import { UserService } from 'src/app/services/user.service';

@NgModule({
    declarations: [OrderModalComponent],
    imports: [CommonModule, FormsModule, MatFormFieldModule, ReactiveFormsModule, MatInputModule, MatButtonModule],
    providers: [OrdersService, UserService],
    exports: [OrderModalComponent],
    entryComponents: [OrderModalComponent]
})
export class OrderModalModule {}