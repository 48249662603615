import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArticlesComponent } from './articles.component';
import { ArticlesService } from 'src/app/services/article.service';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';
import { RouterModule } from '@angular/router';

@NgModule({
    declarations: [ArticlesComponent],
    imports: [CommonModule, MatFormFieldModule, MatSelectModule,
         RouterModule],
    providers: [ArticlesService],
})
export class ArticlesModule {}