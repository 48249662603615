import { Component, OnInit } from '@angular/core';
import { Article } from 'src/app/models/article';
import { SpecialProductService } from './special-product.service';
import { Globals } from 'src/app/services/global-variable.services';
import { CartModel, CartType } from 'src/app/models/cart';

@Component({
  selector: 'laco-special-product',
  templateUrl: './special-product.component.html',
  styleUrls: ['./special-product.component.scss']
})
export class SpecialProductComponent implements OnInit {
  specialOfferItems: Article[] = [];

  constructor(private specialProductService: SpecialProductService, private globals: Globals) {

  }

  ngOnInit() {
    this.loadSpacialOffers();
  }

  loadSpacialOffers() {
    this.specialProductService.getSpecialOffers()
      .subscribe((articles: Article[]) => {
        this.specialOfferItems = articles;
      });
  }

  addToCart(article: Article) {
    const cartItem = new CartModel(article.id, article.category.value, article.name, article.price, 1, article.image, CartType.Article);
    this.globals.addToCart(cartItem);
  }

  removeFromCart(part) {
    this.globals.removeFromCart(part);
  }
}
