import { Component, OnInit } from '@angular/core';
import { Article } from 'src/app/models/article';
import { Globals } from 'src/app/services/global-variable.services';
import { CartModel, CartType } from 'src/app/models/cart';
import { SpecialProductService } from '../special-product/special-product.service';

@Component({
  selector: 'laco-popular-product',
  templateUrl: './popular-product.component.html',
  styleUrls: ['./popular-product.component.scss']
})
export class PopularProductComponent implements OnInit {

  title = 'laco-app';
  specialOfferItems: Article[] = [];

constructor(private specialProductService: SpecialProductService, private globals: Globals) {
}

ngOnInit() {
  this.loadSpacialOffers();
}

loadSpacialOffers() {
  this.specialProductService.getPopular()
    .subscribe((articles: Article[]) => {
      this.specialOfferItems = articles;
    });
}

addToCart(article: Article) {
  const cartItem = new CartModel(article.id, article.category.value, article.name, article.price, 1, article.image, CartType.Article);
  this.globals.addToCart(cartItem);
}

removeFromCart(part) {
  this.globals.removeFromCart(part);
}
}