import { NgModule } from '@angular/core';
import { PartsComponent } from './parts.component';
import { CommonModule } from '@angular/common';
import { FilterModalModule } from 'src/app/components/shared/filter-modal/filter-modal.module';
import { MatCheckboxModule, MatDialogModule, MatDialog } from '@angular/material';
import { RouterModule } from '@angular/router';
import { BikeService } from 'src/app/services/bike.services';
import { PartService } from 'src/app/services/part.service';
import { FormsModule } from '@angular/forms';
import { ProductBannerModule } from 'src/app/components/product-banner/product-banner.module';

@NgModule({
    declarations: [PartsComponent],
    imports: [CommonModule, FilterModalModule, MatCheckboxModule, RouterModule, ProductBannerModule,
        MatDialogModule, FormsModule],
    providers: [MatDialog, BikeService, PartService],
})
export class PartsModule { }