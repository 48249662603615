import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { NewsItemModel } from '../models/news';

@Injectable()
export class NewsService  implements Resolve<any> {
    routeParams: any;
    item: NewsItemModel;
    onItemChanged: BehaviorSubject<any>;

    constructor(private http: HttpClient) {
        this.onItemChanged = new BehaviorSubject([]);

    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getNews(),
            ]).then(
                ([]) => {
                    resolve();
                },
                reject
            );
        });
    }
    getNews() {
        return new Promise((resolve, reject) => {
            this.http.get('../assets/data/news.json')
                .subscribe((response: NewsItemModel[]) => {
                    this.item = response.find(p => p.id == this.routeParams.id);
                    this.onItemChanged.next(this.item);
                    resolve(this.item);
                }, reject);
        }
        );
    }
}


