export class CartModel {
    constructor(id: number,
                category: string,
                name: string,
                price: number,
                quantity: number,
                image: string,
                type: CartType) {

        this.id = id;
        this.name = name;
        this.price = price;
        this.quantity = quantity;
        this.image = image;
        this.category = category;
        this.type = type;
    }

    public id: number;
    public category: string;
    public name: string;
    public price: number;
    public quantity: number;
    public image: string;
    public type: CartType;
}

export enum CartType {
    Part,
    Article
}
