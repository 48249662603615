import { Component, OnInit } from '@angular/core';
import { Banner } from 'src/app/models/banner';
import { SliderService } from './slider.service';
import { ScriptService } from 'src/app/services/script.service';

declare function showSlider(): any;
declare function renderFilter(): any;

@Component({
  selector: 'laco-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {
  title = 'laco-app';
  banners: Banner[] = [];
  isSliderShown = false;
  constructor(private script: ScriptService, private sliderService: SliderService) {

  }

  ngOnInit() {
    this.getBanners();
  }

  getBanners() {
    this.sliderService.getBanners()
      .subscribe((data: Banner[]) => {
        this.banners = data;
        this.isSliderShown = true;
        this.loadFrontSlider();
      });
  }


  loadFrontSlider() {
    this.script.load('jquery').then(() => {


      this.script.load('fractionslider').then(() => {


        this.script.load('fancybox').then(() => {
          this.script.load('slider').then(() => {
            setTimeout(() => {
              showSlider();
              this.script.load('main').then(() => {
                renderFilter();
              }).catch(error => console.log(error));
            }, 100);
          });
        }).catch(error => console.log(error));

      }).catch(error => console.log(error));
    }).catch(error => console.log(error));
  }
}
