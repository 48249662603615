import { NgModule } from '@angular/core';
import { ArticleDetailsComponent } from './article-details.component';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material';
import { DetailModule } from 'src/app/components/shared/detail/detail.module';

@NgModule({
    declarations: [ArticleDetailsComponent],
    imports: [CommonModule, DetailModule, MatButtonModule, CKEditorModule, FormsModule],
    providers: [],
})
export class ArticleDetailsModule { }