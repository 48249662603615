import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersService } from 'src/app/services/order.services';
import { CartComponent } from './cart.component';
import { OrderModalModule } from 'src/app/components/shared/order-modal/order-modal.module';
import { RegisterModalModule } from 'src/app/components/shared/register-modal/register-modal.module';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material';

@NgModule({
    declarations: [CartComponent],
    imports: [CommonModule, OrderModalModule, RegisterModalModule, RouterModule, MatButtonModule],
    providers: [OrdersService],
})
export class CartModule { }