import { Component, OnInit } from '@angular/core';
import { ArticlesService } from 'src/app/services/article.service';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/services/global-variable.services';
import { CartModel, CartType } from 'src/app/models/cart';
import { ArticleModel } from 'src/app/models/article';

@Component({
  selector: 'laco-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit {

  categoryId: number;
    articles: ArticleModel[] = [];
    sortSelected = '1';
    constructor(private articlesService: ArticlesService, private route: ActivatedRoute, private globals: Globals) {
        this.categoryId = this.route.snapshot.params.id;
    }

    ngOnInit() {
        this.globals.setActiveMenu('Ostalo');

        this.articlesService.onArticleChanged
            .subscribe(articles => {
                this.articles = articles.map(artical =>
                    new ArticleModel(artical.id,
                        artical.name, artical.image,
                        artical.price,
                        artical.category.name,
                        artical.description,
                        artical.ean, 1, artical.stock));
            });
    }

    discauntQuantity(article) {
        if (article.quantity > 0) {
            article.quantity--;
        }
    }

    addQuantity(article) {
        if (article.quantity < article.stock) {
            article.quantity++;
        }
    }

    addToCart(article: ArticleModel) {
        const cartItem = new CartModel(article.id, article.category, article.name, article.price, article.quantity,
             article.image, CartType.Article);
        this.globals.addToCart(cartItem);
      }

      removeFromCart(part) {
        this.globals.removeFromCart(part);
      }

      changeSortBy() {
        if (this.sortSelected === '1') {
            this.articles = this.articles.sort((a, b) => (a.price < b.price) ? 1 : -1);
        } else {
            this.articles = this.articles.sort((a, b) => (a.price > b.price) ? 1 : -1);
        }
      }
}
