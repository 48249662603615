import { NgModule } from '@angular/core';
import { DetailComponent } from './detail.component';
import { DetailsService } from 'src/app/services/detail.services';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [DetailComponent],
    imports: [CommonModule, MatButtonModule, CKEditorModule, FormsModule],
    providers: [DetailsService],
    exports: [DetailComponent]
})
export class DetailModule {}