import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class BikeService {
  rootPath = ''
  bikesUrl = this.rootPath + '/api/bike/GetAllBikes';
  brandsUrl = this.rootPath + '/api/bike/brand';
  modelssUrl = this.rootPath + '/api/bike/model';
  ccsUrl = this.rootPath + '/api/bike/cc';
  yearUrl = this.rootPath + '/api/bike/year';
  bikePartsGroupByTypeUrl = this.rootPath + '/api/BikeParts/getBikePartGroupByType';
  bikes: any;
    observable: any;
    constructor(private http: HttpClient) { }

  getAllBikes() {
    return this.http.get(this.bikesUrl);
  }

  getBrands(bikeFilter) {
      return this.http.post(this.brandsUrl, bikeFilter);
  }

  getModels(bikeFilter) {
      return this.http.post(this.modelssUrl, bikeFilter);
  }

  getCcs(bikeFilter) {
      return this.http.post(this.ccsUrl, bikeFilter);
  }

  getYears(bikeFilter) {
      return this.http.post(this.yearUrl, bikeFilter);
  }

  getBikes(bikeFilter) {
      return this.http.post(this.modelssUrl, bikeFilter);
  }

  getBikePartsGroupedByType(bikeFilter) {
    return this.http.post(this.bikePartsGroupByTypeUrl, bikeFilter);
  }

}
